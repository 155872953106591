import { v4 as uuidv4 } from "uuid";

export async function convertHeic(heicFile: File) {
  const heic2any = require("heic2any");
  const convertedBlob = await heic2any({ blob: heicFile });
  console.log("convertedBlob", convertedBlob);
  const convertedFile = new File([convertedBlob], `${uuidv4()}.png`, {
    type: "image/png",
  });
  console.log("convertedFile:", convertedFile);
  return { convertedBlob, convertedFile };
}

export async function handleImagesSelected(
  files: any[],
  processImages: (arg0: any[]) => void
) {
  const formattedFiles: any[] = [];
  const allImages: any[] = [];
  const heicPlaceholders: any[] = [];

  files.forEach((file: File, index: number) => {
    const blobUrl = URL.createObjectURL(file);
    if (file?.type !== "image/heic") {
      formattedFiles.push(file);
      allImages.push({
        data: blobUrl,
        name: file.name,
        size: file.size,
        type: file.type,
        scale: 1,
        rotate: 0,
        id: uuidv4(),
      });
    } else {
      const placeholder = {
        data: "loading",
        name: file.name,
        size: file.size,
        type: "image/heic",
        scale: 1,
        rotate: 0,
        id: uuidv4(),
      };
      formattedFiles.push(placeholder);
      allImages.push(placeholder);
      heicPlaceholders.push({ index, id: placeholder.id });
    }
  });

  processImages(allImages);

  const heicFiles = files.filter((file: File) => file?.type === "image/heic");

  for (let i = 0; i < heicFiles.length; i++) {
    const file = heicFiles[i];
    const { convertedBlob, convertedFile } = await convertHeic(file);
    const blobUrl = URL.createObjectURL(convertedBlob);
    const convertedImage = {
      data: blobUrl,
      name: file.name,
      size: file.size,
      type: "image/png",
      scale: 1,
      rotate: 0,
      id: uuidv4(),
    };

    const placeholder = heicPlaceholders[i];
    if (placeholder) {
      formattedFiles[placeholder.index] = convertedFile;
      allImages[placeholder.index] = convertedImage;
    }

    processImages(allImages);
  }

  return formattedFiles;
}

export const imagesFileTypes = {
  "image/*": [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".bmp",
    ".svg",
    ".tiff",
    ".tif",
    ".ico",
    ".webp",
  ],
  "image/heic": [".heic"],
};

export const allFileTypes = {
  "image/*": [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".bmp",
    ".svg",
    ".tiff",
    ".tif",
    ".ico",
    ".webp",
  ],
  "video/*": [
    ".mp4",
    ".mov",
    ".wmv",
    ".avi",
    ".flv",
    ".mkv",
    ".webm",
    ".mpeg",
    ".mpg",
    ".ogv",
  ],
  "image/heic": [".heic"],
};
