import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";

import LogOutButton from "../../common/LogOutButton/LogOutButton";
import { DesktopContext } from "../../../contexts/DesktopContextProvider/DesktopContextProvider";
import TopLineAdminDesktopNavigation from "../TopLineAdminDesktopNavigation/TopLineAdminDesktopNavigation";
import { ArrowRightIcon } from "@heroicons/react/solid";
import ToplineProAdminToggle from "../ToplineProAdminToggle/ToplineProAdminToggle";
import HighlightedTabWithTooltip from "./HighlightedTabWithTooltip";
import { Tooltip } from "@material-tailwind/react";
import { DESKTOP_NAVIGATION_EXPANDED_WIDTH } from "./constant";
import SiteSelector from "./SiteSelector";

export default function DesktopNavigationExpanded() {
  const router = useRouter();
  const {
    selectedFrame,
    handleDesktopMenuOpen,
    bottomLeftNavigation,
    showContactsTooltip,
    showReviewsTooltip,
  } = useContext(DesktopContext);

  const [focusHelpAndSupport, setFocusHelpAndSupport] =
    useState<boolean>(false);

  useEffect(() => {
    if (router.query.focusHelpAndSupport) {
      setFocusHelpAndSupport(true);
    } else {
      setFocusHelpAndSupport(false);
    }
  }, [router.query.focusHelpAndSupport]);

  const checkIfSelected = (item: string) => {
    // check if item Reges match to last route from local storage
    const itemRegex = `^${item}.*`;
    if (typeof window !== "undefined") {
      const lastRoute = localStorage.getItem("lastRoute");

      // item matched patter save to local storage
      if (lastRoute?.match(itemRegex)) {
        localStorage.setItem("lastRoute", item);
        return true;
      }
    }
    const pathNameWithoutParams = itemRegex.split("?").length
      ? itemRegex.split("?")[0]
      : itemRegex;

    const doesPathMatch =
      router.pathname !== "/settings/refer-a-friend"
        ? router.pathname.match(pathNameWithoutParams)
        : pathNameWithoutParams === "^/settings/refer-a-friend";

    return doesPathMatch;
  };

  return (
    <>
      <div
        id="desktop-navigation-expanded"
        className="hidden lg:flex"
        onMouseLeave={() => handleDesktopMenuOpen(false)}
      >
        <div
          className="h-full z-50"
          style={{ width: DESKTOP_NAVIGATION_EXPANDED_WIDTH }}
        >
          <div
            className="fixed h-full flex flex-col overflow-y-auto bg-gray-700"
            style={{ width: "200px" }}
          >
            <div id="desktop-sidebar" className="flex-1 flex flex-col">
              <div className="flex-shrink-0 py-4 flex items-center justify-start pl-3">
                <Link href="/home">
                  <a>
                    <img
                      src="/Logo_Light.svg"
                      alt="Topline Pro Logo"
                      className="h-6 w-auto "
                    />
                  </a>
                </Link>
              </div>
              <SiteSelector />
              <nav
                aria-label="Sidebar"
                className="pt-3 flex flex-col items-between space-y-1"
              >
                {selectedFrame.map((item) => (
                  <div key={item.name}>
                    {(item.name === "Contacts" && showContactsTooltip) ||
                    (item.name === "Reviews" && showReviewsTooltip) ? (
                      <div className="sm:block hidden">
                        <Tooltip
                          content={
                            <div className="sm:block hidden">
                              <HighlightedTabWithTooltip />
                            </div>
                          }
                          placement="top-right"
                          open={showContactsTooltip || showReviewsTooltip}
                          className="bg-clear"
                          offset={60}
                          dismiss={{ enabled: true, outsidePointerDown: true }}
                        >
                          <div className="group flex justify-start space-x-3 align-middle items-center text-sm-normal rounded-md pr-4 pl-2 py-3 mx-2 text-gray-700 bg-white rounded-md border-4 border-blue-300 text-sm-medium">
                            <item.icon
                              className={"h-5 w-5 text-blue-700"}
                              aria-hidden="true"
                            />
                            <span>{item.name}</span>
                          </div>
                        </Tooltip>
                      </div>
                    ) : (
                      <Link key={item.name} href={item?.href}>
                        <a
                          target={item?.target}
                          className={`group flex justify-start space-x-3 align-middle items-center text-sm-normal rounded-md pr-4 pl-2 py-3 mx-2 ${
                            checkIfSelected(item?.href.toLocaleLowerCase())
                              ? "text-white bg-gray-500"
                              : "hover:bg-gray-600 hover:text-white text-gray-300"
                          }  cursor-pointer`}
                        >
                          {checkIfSelected(item?.href.toLocaleLowerCase()) ? (
                            <item.selectedIcon
                              className={"h-5 w-5"}
                              aria-hidden="true"
                            />
                          ) : (
                            <item.icon
                              className={"h-5 w-5"}
                              aria-hidden="true"
                            />
                          )}
                          <span>{item.name}</span>
                        </a>
                      </Link>
                    )}
                  </div>
                ))}
              </nav>
              <nav className="mt-auto p-2 space-y-2 ">
                <TopLineAdminDesktopNavigation />
                <ToplineProAdminToggle />
              </nav>
            </div>
            <div className="bg-gray-600 px-4 py-3 border-t border-b border-gray-500 mb-4">
              <div className="text-sm-bold text-white">🤑 Refer, Get Paid</div>
              <p className="text-xs-normal uppercase text-gray-200 mt-1">
                1 Referral = $200 Reward
              </p>
              <div className="text-xs-medium mt-4 text-gray-50">
                <Link href="/settings/refer-a-friend">
                  <a className="flex gap-1">
                    Earn rewards <ArrowRightIcon className="w-4 h-4" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="space-y-1 pb-6">
              {bottomLeftNavigation.map((item) => (
                <Link key={item.name} href={item?.href}>
                  <a
                    target={item?.target}
                    className={`group flex justify-start align-middle items-center space-x-3 text-sm-normal rounded-md pr-4 pl-2 py-3 mx-2 ${
                      checkIfSelected(item?.href.toLocaleLowerCase())
                        ? "text-white bg-gray-500"
                        : "hover:bg-gray-600 hover:text-white text-gray-300"
                    }  cursor-pointer ${
                      focusHelpAndSupport && item.name === "Help & Support"
                        ? "animate-bounce-6 hover:bg-blue-100 hover:text-gray-700 border-blue-300 border-4 bg-white text-gray-500"
                        : ""
                    }`}
                  >
                    {checkIfSelected(item?.href.toLocaleLowerCase()) ? (
                      <item.selectedIcon
                        className={`${
                          item.name === "Help & Support" ||
                          item.name === "Get $100"
                            ? "-my-0.5 -mx-0.5 h-6 w-6"
                            : "h-5 w-5"
                        } `}
                        aria-hidden="true"
                      />
                    ) : (
                      <item.icon
                        className={`${
                          item.name === "Help & Support" ||
                          item.name === "Get $100"
                            ? "-my-0.5 -mx-0.5 h-6 w-6"
                            : "h-5 w-5"
                        } ${
                          focusHelpAndSupport && item.name === "Help & Support"
                            ? "text-blue-500"
                            : ""
                        }`}
                        aria-hidden="true"
                      />
                    )}
                    <span>{item.name}</span>
                  </a>
                </Link>
              ))}
              <LogOutButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
