import React, { useContext, useState, useEffect } from "react";
import { DesktopContext } from "../../../contexts/DesktopContextProvider/DesktopContextProvider";
import SecondaryButton from "../../uiwrappers/SecondaryButton/SecondaryButton";
import { useRouter } from "next/router";
import useIsMobile from "../../../hooks/useIsMobile";

function HighlightedTabWithTooltip() {
  const [header, setHeader] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [subheader, setSubheader] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [position, setPosition] = useState("");
  const {
    showContactsTooltip,
    showReviewsTooltip,
    setShowContactsTooltip,
    setShowReviewsTooltip,
  } = useContext(DesktopContext);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (showContactsTooltip) {
      setHeader("Sync Contacts, Get Reviews");
      setSubheader(
        "Sync your contacts in order to request reviews from your customers"
      );
      setOpen(showContactsTooltip);
      setButtonText("Sync Contacts");
      setPosition("sm:top-12 top-0");
    } else if (showReviewsTooltip) {
      setHeader("More Reviews = More Jobs");
      setSubheader(
        "Request reviews from customers to boost your Google ranking"
      );
      setOpen(showReviewsTooltip);
      setButtonText("Get Reviews");
      setPosition("sm:bottom-36 bottom-0");
    }
  }, [showContactsTooltip, showReviewsTooltip]);

  function handleClose() {
    if (showContactsTooltip) {
      setShowContactsTooltip(false);
      setShowReviewsTooltip(true);
    } else if (showReviewsTooltip) {
      setShowReviewsTooltip(false);
    }
  }

  function handleButtonClick() {
    if (showContactsTooltip) {
      router.push("/contacts");
      setShowContactsTooltip(false);
    } else if (showReviewsTooltip) {
      router.push("/reviews?sendReviewRequest=true");
      setShowReviewsTooltip(false);
    }
  }

  const router = useRouter();
  if (showContactsTooltip || showReviewsTooltip)
    return (
      <div>
        <div
          className="inline-block bg-blue-600 fixed rounded-md z-50"
          style={{ left: isMobile ? "36px" : "215px" }}
        >
          <div
            className={` text-white py-5 px-4 relative`}
            style={{ width: "316px" }}
          >
            <div className="text-white">
              <div className="flex justify-between">
                <div className="text-lg font-bold mb-2.5">{header}</div>
              </div>
              <div className="text-body-normal mb-5">{subheader}</div>

              <div className="flex justify-end">
                <SecondaryButton
                  text={buttonText}
                  size="md"
                  onClickFunc={handleButtonClick}
                />
              </div>
            </div>

            {!isMobile && (
              <div
                className="absolute top-0 -left-2 triangle-left"
                style={{ top: "45%" }}
              ></div>
            )}
            {isMobile && showContactsTooltip && (
              <div className="absolute -top-1.5 right-5 triangle-up"></div>
            )}
            {isMobile && showReviewsTooltip && (
              <div className="absolute -bottom-1.5 right-20">
                <div className="triangle-down"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  else return <></>;
}

export default HighlightedTabWithTooltip;
