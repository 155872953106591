import { SparklesIcon } from "@heroicons/react/solid";
import { useContext, useEffect } from "react";
import ChatbotModal from "./ChatbotModal";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { useRouter } from "next/router";
import useIsMobile from "../../../../hooks/useIsMobile";
import BlueTooltip from "../../../uiwrappers/BlueTooltip/BlueTooltip";
import { isPrimaryPage } from "./utils";

export default function Chatbot() {
  const router = useRouter();
  const isMobile = useIsMobile();
  const {
    chatbotOpen,
    setChatbotOpen,
    showChatbotTooltip2,
    setShowChatbotTooltip2,
  } = useContext(DesktopContext);

  function openChatbot() {
    setChatbotOpen(true);
  }

  function showAssistant() {
    return isPrimaryPage(router.pathname);
  }

  useEffect(() => {
    const chatbotTooltip2 = localStorage.getItem("chatbotTooltip2");
    if (!chatbotTooltip2 && showAssistant()) {
      setShowChatbotTooltip2(true);
      localStorage.setItem("chatbotTooltip2", "shown");
    }
  }, [router?.pathname]);

  return (
    <div>
      <ChatbotModal isOpen={chatbotOpen} setIsOpen={setChatbotOpen} />

      {showAssistant() && (
        <>
          <BlueTooltip
            isOpen={showChatbotTooltip2}
            setOpen={setShowChatbotTooltip2}
            header="Topline Pro Assistant"
            subheader="Access anywhere within the app. Just tap."
            arrowDirection="triangle-down"
            arrowPosition={{
              bottom: -8,
              right: isMobile ? "4%" : "7%",
            }}
            relativePosition={
              isMobile
                ? { right: "0%", bottom: 60 }
                : { right: "0%", bottom: 80 }
            }
          />

          <div
            onClick={openChatbot}
            className="flex items-center justify-center sm:w-14 sm:h-14 w-12 h-12 bg-purple-600 rounded-full cursor-pointer shadow-xl"
          >
            <SparklesIcon className="sm:w-7 sm:h-7 w-6 h-6 text-white" />
          </div>
        </>
      )}
    </div>
  );
}
