import React from "react";

const QueueIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.59961 18.6001H18.6374M3.59961 13.9779H18.6374"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3356 8.93323L4.41396 8.93323C3.30167 8.93323 2.39998 7.96317 2.39998 6.76656C2.39998 5.56995 3.30167 4.59989 4.41396 4.59989L12.3356 4.59989M21.6 6.911L18.3776 6.911M18.3776 6.911L15.1552 6.911M18.3776 6.911L18.3776 3.44434M18.3776 6.911L18.3776 10.3777"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default QueueIcon;
