export function fixHeightOfBody(isMobile: boolean, open: boolean) {
  if (isMobile) {
    if (open) {
      setTimeout(() => {
        afterEnter();
      }, 0);
    } else {
      afterLeave();
    }
  }
  if (!isMobile && !open) {
    afterLeave();
  }
}
function afterEnter() {
  let scroll = 0;
  try {
    if (document.body.style.marginTop) {
      scroll = parseInt(document.body.style.marginTop.split("px")[0]) * -1;
    } else {
      scroll = window.scrollY;
    }
  } catch (e) {
    scroll = window.scrollY;
  }

  document.body.style.height = `calc(100svh + ${scroll}px)`;
  document.body.style.overflow = "hidden";
  document.body.style.position = "relative";
  document.body.style.touchAction = "none";
}
function afterLeave() {
  document.body.style.height = "";
  document.body.style.overflow = "";
  document.body.style.position = "";
  document.body.style.touchAction = "";
}
