import { CameraIcon } from "@heroicons/react/outline";
import { ArrowUpIcon, XCircleIcon } from "@heroicons/react/solid";
import { KeyboardEvent, useContext, useEffect, useRef, useState } from "react";
import ImageUpload from "../../../uiwrappers/ImageUpload/ImageUpload";
import { createFile } from "../../editor/BeforeAndAfterModal/utils";
import { getS3File } from "../../editor/utils";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";

export default function ChatInput({
  onSend,
  attachedPhoto,
  setAttachedPhoto,
  loading,
  setLoading,
}: any) {
  const { profileInfo } = useContext(DesktopContext);
  const slug = profileInfo?.slug;

  const [content, setContent] = useState<string>();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value;
    setContent(value);
  }

  async function handleSend() {
    if (loading) return;
    if (!content && !attachedPhoto) return;

    setLoading(true);

    const trimmedContent = content?.trim() || "";
    const photo = attachedPhoto;

    setContent("");
    setAttachedPhoto("");

    let messageObject = {
      role: "user",
      content: trimmedContent,
      image: "",
    };
    if (photo) {
      const file = await createFile(photo);
      const signedRequest = await getS3File(file);
      const photoUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${signedRequest}`;

      messageObject = {
        role: "user",
        content: trimmedContent,
        image: photoUrl,
      };
    }

    onSend(messageObject);
  }

  function handleKeyDown(e: KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  }

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "inherit";
      const scrollHeight = textareaRef.current?.scrollHeight;
      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [content]);

  useEffect(() => {
    if (textareaRef) {
      setTimeout(() => {
        textareaRef.current?.blur();
      }, 0);
    }
  }, []);

  function handleImagesSelected(images: any[]) {
    const image = images[0];
    setAttachedPhoto(image?.data);
  }

  return (
    <div className="flex flex-col w-full rounded-lg overflow-hidden border border-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-600">
      {attachedPhoto && (
        <div className="py-2 pl-2">
          <div className="relative w-14 h-14 group">
            <div
              onClick={() => setAttachedPhoto("")}
              className="cursor-pointer absolute -right-1.5 -top-1.5 w-4 h-4 opacity-0 group-hover:opacity-100 transition duration-300"
            >
              <XCircleIcon className="rounded-full bg-red-600 text-white" />
            </div>
            <img
              src={attachedPhoto}
              className="w-14 h-14 rounded-lg object-cover"
            />
          </div>
        </div>
      )}

      <div className="flex">
        <div className="flex absolute left-6 bottom-6 items-end cursor-pointer">
          <ImageUpload processImages={handleImagesSelected} multiple={false}>
            <CameraIcon className="h-7 w-7 rounded-full p-1 text-gray-700" />
          </ImageUpload>
        </div>

        <textarea
          id="chat-input"
          ref={textareaRef}
          style={{ resize: "none" }}
          className="px-10 p-3 w-full sm:text-sm-normal rounded-lg focus:ring-0 border-none"
          placeholder="Talk to your Topline Pro Assistant"
          rows={1}
          value={content}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />

        <button
          className="flex absolute right-6 bottom-6 items-end cursor-pointer"
          onClick={handleSend}
        >
          <ArrowUpIcon className="h-7 w-7 cursor-pointer rounded-full p-1 text-gray-700" />
        </button>
      </div>
    </div>
  );
}
