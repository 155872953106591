import { useState, useEffect } from "react";
import { ILineLoaderProps } from "./types";

const LineLoader = ({
  steps = 5,
  grayHeight = 1.5,
  blueHeight = 1.5,
}: ILineLoaderProps) => {
  const [width, setWidth] = useState(0);
  const [direction, setDirection] = useState("forward");

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (direction === "forward") {
        setWidth((prevWidth) => prevWidth + 100 / steps);
        if (width >= 100) {
          setDirection("backward");
        }
      } else {
        setWidth((prevWidth) => prevWidth - 100 / steps);
        if (width <= 0) {
          setDirection("forward");
        }
      }
    }, 200);

    return () => clearInterval(intervalId);
  }, [width, direction, steps]);

  return (
    <div
      className={`w-full bg-gray-200 rounded-full h-${grayHeight} transform transition-all duration-200 ease-in-out`}
    >
      <div
        className={`bg-blue-600 h-${blueHeight} rounded-full`}
        style={{ width: `${width}%` }}
      ></div>
    </div>
  );
};

export default LineLoader;
