import React from "react";
import { LogoutIcon } from "@heroicons/react/solid";
import { signOut } from "next-auth/react";
interface LogOutButtonProps {
  onClickHandler?: () => void;
  className?: string;
  showLogo?: boolean;
}

const LogOutButton = ({
  onClickHandler,
  className,
  showLogo = true,
}: LogOutButtonProps) => {
  const handleLogOutCLick = () => {
    if (typeof localStorage !== "undefined") {
      localStorage.clear();
    }
    if (typeof sessionStorage !== "undefined") {
      sessionStorage.clear();
    }
    signOut({ callbackUrl: "/login" });
  };

  return (
    <a
      onClick={onClickHandler ? onClickHandler : handleLogOutCLick}
      className="flex cursor-pointer group p-2 pl-4 rounded-md items-center text-gray-500 lg:text-gray-300 text-sm-medium hover:text-gray-700 hover:bg-blue-50 lg:justify-start lg:space-x-3 lg:mx-2 lg:my-0.5 lg:rounded-md lg:pl-2 lg:py-3 lg:mb-6 lg:hover:bg-gray-600 lg:hover:text-white"
    >
      {showLogo && (
        <LogoutIcon
          className="mr-4 sm:m-0 h-5 w-5 text-gray-300 group-hover:text-gray-700 lg:group-hover:text-white"
          aria-hidden="true"
        />
      )}
      <span>Log out</span>
    </a>
  );
};

export default LogOutButton;
