import React, { useContext, useEffect, useMemo, useState } from "react";
import Script from "next/script";
// import { HeapAnalyticsProps } from "./types";
import { useSession } from "next-auth/react";
import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";
import { extractHeapInfoFromProfileInfo, getHeapAnalyticsId } from "./utils";
import { AdminContext } from "../../contexts/AdminDesktopNavigationContext/AdminDesktopNavigationContext";
import * as Sentry from "@sentry/nextjs";

const HeapAnalytics: React.FC = () => {
  const appId = useMemo(() => getHeapAnalyticsId(), []);
  const { status } = useSession();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { profileInfo } = useContext(DesktopContext);

  const { isToplineProAdminUser } = useContext(AdminContext);

  useEffect(() => {
    try {
      if (typeof window === "undefined") {
        return;
      }
      if (
        status &&
        status === "authenticated" &&
        window?.heap &&
        profileInfo?.customer_pk &&
        profileInfo?.current_plan?.type
      ) {
        const userId = localStorage.getItem("userId") || "";

        if (userId) {
          try {
            window.heap?.identify(JSON.parse(userId));
          } catch (e) {
            console.log(e);
          }
        }

        const heapInfo = extractHeapInfoFromProfileInfo(profileInfo);

        Sentry.setUser({ id: userId, email: heapInfo?.email });

        if (window?.heap?.addUserProperties) {
          window?.heap?.addUserProperties({
            ...heapInfo,
            isToplineProAdminUser,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [
    scriptLoaded,
    status,
    profileInfo?.customer_pk,
    profileInfo?.current_plan,
  ]);

  const scriptReady = () => {
    if (window.heap) {
      setScriptLoaded(true);
    }
  };

  return (
    <Script
      id="heap-analytics"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
      window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
      heap.load("${appId}");
      `,
      }}
      onReady={scriptReady}
    />
  );
};

export default HeapAnalytics;
