import { useRouter } from "next/router";
import React, { useContext, useState } from "react";
import { DesktopContext } from "../../../contexts/DesktopContextProvider/DesktopContextProvider";

import { debounce } from "lodash";
import { ArrowDownIcon } from "@heroicons/react/solid";
import LineLoader from "../../common/LineLoader/LineLoader";
import { debounceGetCustomerByAny } from "../TopLineAdminDesktopNavigation/utils";
import { TopLineAdminDesktopNavigationContext } from "../TopLineAdminDesktopNavigation/TopLineAdminDesktopNavigation";
import { AsUserStore as AsUserStoreSingleton } from "../LandingPageSearch/AsUserStore";
const AsUserStore = AsUserStoreSingleton.getInstance();

const CustomerSearch = () => {
  const router = useRouter();
  const { profileInfo } = useContext(DesktopContext);
  const {
    showCustomerSearch,
    setShowCustomerSearch,
    showLandingPageSearch,
    setShowLandingPageSearch,
  } = useContext(TopLineAdminDesktopNavigationContext);
  const [searchCustomerList, setSearchCustomerList] = useState<any[]>([]);

  const [isSearchQueryLoading, setIsSearchQueryLoading] = useState(false);

  const handleCustomerToggle = () => {
    if (showLandingPageSearch) setShowLandingPageSearch(!showLandingPageSearch);
    setShowCustomerSearch(!showCustomerSearch);
  };

  const handleCustomerSearch = async (e: any) => {
    const { value } = e.target;
    if (value && value.length > 2) {
      setIsSearchQueryLoading(true);
      const response = await debounceGetCustomerByAny(value);
      const { message } = response || ({} as any);
      const { customers } = message;
      setSearchCustomerList(customers);
      setIsSearchQueryLoading(false);
    } else {
      setSearchCustomerList([]);
    }
  };

  const debouncedHandleCustomerSearch = debounce(handleCustomerSearch, 1000);

  const handleCustomerSwitch = (customer: any) => {
    const { auth0_user_id } = customer;
    localStorage.removeItem("CreateCampaignState");
    AsUserStore.id = auth0_user_id;
    setShowCustomerSearch(false);
    router.reload();
  };
  return (
    <div data-testid="CustomerSearch">
      <div className="relative">
        <div
          onClick={handleCustomerToggle}
          className=" border p-3 rounded text-white shadow-inner w-full flex cursor-pointer"
        >
          <span className="float-left text-gray-300">
            {profileInfo?.first_name + " " + profileInfo?.last_name}
          </span>
          <ArrowDownIcon className="w-4 h-6" />
        </div>
        {showCustomerSearch && (
          <div className="rounded shadow-md my-2 absolute z-20 max-h-64 overflow-hidden pin-t pin-l bg-white">
            <ul className="list-reset">
              <li className="p-2">
                <h2>Search Customer</h2>
                <input
                  onChange={debouncedHandleCustomerSearch}
                  className="border-2 rounded h-8 w-full"
                />
                <br />
              </li>
            </ul>
            <div className="max-h-48 overflow-y-scroll">
              <ul className="list-reset">
                {isSearchQueryLoading && <LineLoader />}
                {searchCustomerList.map((item: any, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      handleCustomerSwitch(item);
                    }}
                    className="p-2 block text-black hover:bg-grey-light cursor-pointer"
                  >
                    {item.first_name + " " + item.last_name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerSearch;
