import { useRouter } from "next/router";
import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";
import ChatInput from "./ChatInput";
import ChatMessage from "./ChatMessage";
import ResetChat from "./ResetChat";
import { getQuickActions } from "./utils";
import QuickAction from "./QuickAction";
import ChatHistoryButton from "./ChatHistoryButton";

export default function Chat({
  setIsOpen,
  messages,
  loading,
  setLoading,
  onSend,
  onReset,
  showHistory,
  messagesEndRef,
  attachedPhoto,
  setAttachedPhoto,
  action,
}: any) {
  const router = useRouter();
  const quickActions = getQuickActions();

  function handleQuickAction(action: any) {
    onSend({ role: "user", content: action.message, image: "" });
  }

  function showQuickActions() {
    return !messages.some((message: any) => message.role === "user");
  }

  function handleActionButton() {
    setIsOpen(false);
    router.push(action?.action_url);
    onReset();
  }

  return (
    <>
      <div className="absolute sm:right-14 right-12 top-6 cursor-pointer">
        <ChatHistoryButton showHistory={showHistory} />
      </div>
      <div className="absolute right-4 top-6 cursor-pointer">
        <ResetChat onReset={onReset} />
      </div>

      <div className="relative flex flex-col rounded-lg bg-white px-4 h-full overflow-auto">
        {messages?.map((message: string, index: number) => {
          return (
            <div key={index}>
              <ChatMessage message={message} loading={false} />
            </div>
          );
        })}

        {showQuickActions() && (
          <div className="flex flex-col space-y-1 w-3/4 pb-4">
            {quickActions?.map((action: any) => {
              return (
                <QuickAction
                  handleQuickAction={handleQuickAction}
                  action={action}
                />
              );
            })}
          </div>
        )}

        {loading && (
          <div>
            <ChatMessage
              message={{ role: "assistant", content: "" }}
              loading={true}
            />
          </div>
        )}

        <div>
          {action && (
            <div className="flex pl-10">
              <PrimaryButton
                text={action?.title}
                size="md"
                onClickFunc={handleActionButton}
              />
            </div>
          )}
        </div>

        <div className="flex flex-shrink-0 w-full h-10" ref={messagesEndRef} />
      </div>

      <div className="sticky bottom-0 z-10 left-0 w-full sm:p-4 px-4 py-2">
        <ChatInput
          onSend={onSend}
          attachedPhoto={attachedPhoto}
          setAttachedPhoto={setAttachedPhoto}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </>
  );
}
