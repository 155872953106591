export default function BeforeAfterSvg({ className }: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13 18H19C20.1046 18 21 17.3284 21 16.5V7.5C21 6.67157 20.1046 6 19 6H13M3 14.5V16.5C3 17.3284 3.79594 18 4.77778 18H7.5M3 9.5V7.5C3 6.67157 3.79594 6 4.77778 6H7.5M12 3V21"
        stroke="#ffffff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
