import React from "react";
import { allFileTypes, handleImagesSelected, imagesFileTypes } from "./utils";
import Dropzone from "react-dropzone";

export interface ImageUploadProps {
  processImages: any;
  multiple?: boolean;
  customStyle?: string;
  openModal?: () => void;
  acceptImagesOnly?: boolean;
  children: any;
}

export default function ImageUpload({
  processImages,
  multiple,
  customStyle = "",
  openModal = () => null,
  acceptImagesOnly = false,
  children,
}: ImageUploadProps) {
  return (
    <>
      <Dropzone
        accept={acceptImagesOnly ? imagesFileTypes : allFileTypes}
        multiple={multiple}
        onDrop={async (acceptedFiles) => {
          // used in NewPost.tsx so that post modal is open after user selects photo
          openModal();

          handleImagesSelected(acceptedFiles, processImages);
        }}
      >
        {({ getRootProps, getInputProps, open }) => (
          <div
            {...getRootProps()}
            onClick={open}
            className={`flex ${customStyle}`}
          >
            {children}
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
    </>
  );
}
