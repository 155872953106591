import { useRouter } from "next/router";
import React, { useContext, useState } from "react";
import { DesktopContext } from "../../../contexts/DesktopContextProvider/DesktopContextProvider";

import { debounce } from "lodash";
import { ArrowDownIcon } from "@heroicons/react/solid";
import LineLoader from "../../common/LineLoader/LineLoader";

import {
  debounceGetLandingPageByAny,
  updateCustomerLandingPage,
} from "../TopLineAdminDesktopNavigation/utils";
import { TopLineAdminDesktopNavigationContext } from "../TopLineAdminDesktopNavigation/TopLineAdminDesktopNavigation";
import { getUserId } from "../../../hooks/getAuthUserId";
import { AsUserStore as AsUserStoreSingleton } from "./AsUserStore";
const AsUserStore = AsUserStoreSingleton.getInstance();

const LandingPageSearch = () => {
  const router = useRouter();
  const { profileInfo } = useContext(DesktopContext);
  const {
    showCustomerSearch,
    setShowCustomerSearch,
    showLandingPageSearch,
    setShowLandingPageSearch,
  } = useContext(TopLineAdminDesktopNavigationContext);
  const [searchLandingPageList, setSearchLandingPageList] = useState<any[]>([]);

  const [isSearchQueryLoading, setIsSearchQueryLoading] = useState(false);

  const handleLandingPageToggle = () => {
    if (showCustomerSearch) setShowCustomerSearch(!showCustomerSearch);
    setShowLandingPageSearch(!showLandingPageSearch);
  };
  const handleLandingPageSearch = async (e: any) => {
    const { value } = e.target;
    if (value && value.length > 2) {
      setIsSearchQueryLoading(true);
      const response = await debounceGetLandingPageByAny(value);
      const { message } = response || ({} as any);
      const { landing_pages } = message;
      setSearchLandingPageList(landing_pages);
      setIsSearchQueryLoading(false);
    } else {
      setSearchLandingPageList([]);
    }
  };
  const debouncedHandleLandingPageSearch = debounce(
    handleLandingPageSearch,
    1000
  );

  const handleLandingPageSwitch = async (landingPage: any) => {
    const asUserId = AsUserStore.id;
    const userId = await getUserId();
    localStorage.removeItem("CreateCampaignState");
    if (!asUserId || (userId && userId === asUserId)) {
      // only allow this if we're not impersonating
      await updateCustomerLandingPage({
        landing_page_id: landingPage.id,
        AUTH_O_ID: userId || "",
      });
      router.reload();
    } else {
      alert(`Please connect to your customer in order to change landingpage`);
    }
  };
  return (
    <div data-testid="LandingPageSearch">
      <div className="relative">
        <div
          onClick={handleLandingPageToggle}
          className=" border p-3 rounded text-white shadow-inner w-full flex cursor-pointer"
        >
          <span className="float-left break-all text-gray-300">
            {profileInfo?.slug}
          </span>
          <ArrowDownIcon className="w-4 h-6" />
        </div>
        {showLandingPageSearch && (
          <div className="rounded shadow-md my-2 absolute pin-t pin-l bg-white z-40">
            <ul className="list-reset">
              <li className="p-2">
                <h2>Search LandingPage</h2>
                <input
                  onChange={debouncedHandleLandingPageSearch}
                  className="border-2 rounded h-8 w-full"
                />
                <br />
              </li>
              {isSearchQueryLoading && <LineLoader />}
              {searchLandingPageList.map((item: any, index) => (
                <li
                  key={index}
                  onClick={() => {
                    handleLandingPageSwitch(item);
                  }}
                  className="p-2 block text-black break-all hover:bg-grey-light cursor-pointer"
                >
                  {item.slug}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingPageSearch;
