import { DotsHorizontalIcon } from "@heroicons/react/solid";

export default function ChatLoader() {
  return (
    <div className="flex">
      <div className="flex items-center rounded-full">
        <DotsHorizontalIcon className="w-6 h-6 animate-pulse text-gray-900" />
      </div>
    </div>
  );
}
