import { ProfileInfo } from "../../contexts/types";

export const extractHeapInfoFromProfileInfo = (
  profileInfo: ProfileInfo
): Partial<ProfileInfo> | any => {
  const {
    customer_pk,
    first_name,
    last_name,
    company_name,
    slug,
    personal_phone,
    email,
    stripe_customer_id,
    current_plan,
  } = profileInfo;
  const plan = current_plan?.type;

  return {
    customer_pk,
    first_name,
    last_name,
    company_name,
    slug,
    personal_phone,
    email,
    stripe_customer_id,
    current_plan_type: plan,
  };
};

export const getHeapAnalyticsId = (): string => {
  const heapAnalyticsId = process.env.NEXT_PUBLIC_HEAP_ANALYTICS_ID;
  if (!heapAnalyticsId) {
    return "615107751";
  }

  return heapAnalyticsId;
};
