import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { DesktopContext } from "../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { MobileContext } from "../../../contexts/MobileContextProvider/MobileContextProvider";
import { CONSTANTS } from "../constants";
import HighlightedTabWithTooltip from "../DesktopNavigationExpanded/HighlightedTabWithTooltip";
import { Tooltip } from "@material-tailwind/react";

function MobileBottomNavBar() {
  const router = useRouter();
  const { selectedFrame, mobileMenuOpen, isMobileNavbarVisible } =
    useContext(MobileContext);

  const checkIfSelected = (item: string) => {
    const itemRegex = `^${item}.*`;
    return router.pathname.match(itemRegex);
  };

  const mobileBottomSelectedNavbar = selectedFrame.filter((item) =>
    CONSTANTS.MOBILE_BOTTOM_NAV_OPTION.includes(item.name)
  );
  const { downloadAppOnIphoneWithNoHomeButton, showReviewsTooltip } =
    useContext(DesktopContext);

  const urlsHideMobileNav = [
    "/marketing/digital-media-create",
    "/marketing/digital-media-video-create",
    "/marketing/physical-marketing-create",
  ];
  const hideMobileNav = urlsHideMobileNav.includes(router.pathname);

  if (hideMobileNav) {
    return <></>;
  }

  return (
    <div
      id="mobile-navbar"
      className={`fixed z-20 bottom-0 min-h-12 h-20 bg-gray-700 w-screen lg:hidden shadow-inner 
      ${downloadAppOnIphoneWithNoHomeButton && "h-24 pb-4"} ${
        !isMobileNavbarVisible && "hidden"
      }
      `}
    >
      <div className="mb-2 mx-4 shadow-3xl flex justify-between items-center rounded-md ">
        {mobileBottomSelectedNavbar.map((item) => (
          <div key={item.name}>
            {item.name === "Reviews" && showReviewsTooltip ? (
              <div className="block sm:hidden">
                <Tooltip
                  content={
                    <div className="block sm:hidden">
                      <HighlightedTabWithTooltip />
                    </div>
                  }
                  placement="top"
                  open={showReviewsTooltip}
                  className="bg-clear"
                  offset={200}
                  dismiss={{
                    enabled: true,
                    outsidePointerDown: true,
                  }}
                >
                  <div className="group p-2 py-5 rounded-md flex items-center text-body-medium cursor-pointer tap-highlight-transparent relative">
                    <div className="flex flex-col justify-center items-center align-middle text-center">
                      <div className="flex justify-center">
                        <div className="w-6 h-6">
                          <item.icon aria-hidden="true" />
                        </div>
                      </div>
                      <div className="self-stretch text-center text-gray-200 text-xs-normal font-medium">
                        Reviews
                      </div>
                    </div>
                    <div
                      className="absolute h-16 border-4 border-blue-300 top-2 -left-1 rounded-xl"
                      style={{ height: "62px", width: "70px" }}
                    >
                      <div
                        className="border-2 border-blue-500 rounded-md"
                        style={{ height: "55px" }}
                      ></div>
                    </div>
                  </div>
                </Tooltip>
              </div>
            ) : (
              <Link key={item.name} href={item?.href}>
                <a
                  target={item?.target}
                  className={
                    "group p-2 py-5 rounded-md flex items-center text-body-medium   cursor-pointer tap-highlight-transparent"
                  }
                >
                  <div
                    id={`${item.name.toLowerCase()}`}
                    className={`flex flex-col justify-center items-center align-middle text-center ${
                      checkIfSelected(item?.href.toLocaleLowerCase()) &&
                      !mobileMenuOpen
                        ? "text-white"
                        : "text-gray-100"
                    }`}
                  >
                    {checkIfSelected(item?.href.toLocaleLowerCase()) ? (
                      <div className="text-white">
                        <item.selectedIcon
                          className={`h-6 w-6`}
                          aria-hidden="true"
                        />
                      </div>
                    ) : (
                      <div className="text-white">
                        <item.icon className={`h-6 w-6`} aria-hidden="true" />
                      </div>
                    )}
                    <span
                      className={`${
                        checkIfSelected(item?.href.toLocaleLowerCase())
                          ? "text-white"
                          : "text-gray-200"
                      } text-xs-normal mt-1`}
                    >
                      {item.name}
                    </span>
                  </div>
                </a>
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MobileBottomNavBar;
