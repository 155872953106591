import React, { createContext, useState } from "react";
import { PaymentsContextType } from "../../../../contexts/types";

export const PaymentsContext = createContext({} as PaymentsContextType);

export function PaymentsContextProvider({ children }: any) {
  const [accountInfo, setAccountInfo] = useState<any>();
  const [invoices, setInvoices] = useState<any>();
  const [invoicesFromDb, setInvoicesFromDb] = useState<any>();
  const [quotes, setQuotes] = useState<any>();

  const PaymentsContextValue: PaymentsContextType = {
    accountInfo,
    setAccountInfo,
    invoices,
    setInvoices,
    invoicesFromDb,
    setInvoicesFromDb,
    quotes,
    setQuotes,
  };

  return (
    <PaymentsContext.Provider value={PaymentsContextValue}>
      {children}
    </PaymentsContext.Provider>
  );
}
