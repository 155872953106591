import { api } from "../../../../helpers/topline-api";
import { ChatMessage } from "./ChatbotModal";

export async function postChat(
  messages: ChatMessage[],
  conversation_id: any,
  bot_type: any
) {
  try {
    const response = await api.post(`/api/chat`, {
      messages,
      conversation_id,
      bot_type,
    });

    return response?.data?.message;
  } catch (e) {
    console.log("error:", e);
    return "";
  }
}

export async function getChatHistory() {
  try {
    const response = await api.get(`/api/get-chat-history`);
    return response?.data?.message;
  } catch (e) {
    console.log("error:", e);
    return "";
  }
}

export const chatActions = [
  {
    title: "Send a payment request",
    description: "Send a payment request to a customer",
    action_url: "/payments?requestPayment=true",
  },
  {
    title: "Add a service",
    description: "Add a service to my website",
    action_url: "/site/services?addService=true",
  },
  {
    title: "Edit a service",
    description: "Edit a service on my website",
    action_url: "/site/services",
  },
  {
    title: "Edit ads campaign",
    description: "Edit Google Ads campaign",
    action_url: "/marketing?editCampaign=true",
  },
  {
    title: "Review social media posts",
    description: "Review social media posts",
    action_url: "/social",
  },
  {
    title: "View contacts",
    description: "View contacts",
    action_url: "/contacts",
  },
  {
    title: "Edit a blog",
    description: "Edit a blog post",
    action_url: "/site/blogs",
  },
  {
    title: "Edit galleries",
    description: "Edit photo galleries on my website",
    action_url: "/site",
  },
  {
    title: "Edit business info",
    description: "Edit business info on my website",
    action_url: "/site/info",
  },
  {
    title: "Create ads campaign",
    description: "Create a Google Ads campaign",
    action_url: "/marketing/createCampaign",
  },
  {
    title: "View campaign performance",
    description: "View Google Ads campaign performance",
    action_url: "/marketing",
  },
  {
    title: "Contact support",
    description: "Contact Topline Pro support",
    action_url: "?focusHelpAndSupport=true",
  },
  {
    title: "Respond to a review",
    description: "Respond to a customer review",
    action_url: "/reviews",
  },
  {
    title: "View my website",
    description: "View my website",
    action_url: "/home?focusSite=true",
  },
  {
    title: "View bookings",
    description: "View bookings received on my website",
    action_url: "/bookings",
  },
  {
    title: "View payments",
    description: "View my payments info",
    action_url: "/payments",
  },
  {
    title: "Generate a blog",
    description: "Generate a blog post for my website",
    action_url: "/site/generate-blog",
  },
  {
    title: "Add photos",
    description: "Add photos to my website",
    action_url: "/site?addPhoto=true",
  },
  {
    title: "Send a review request",
    description: "Send a review request to a customer",
    action_url: "/reviews?sendReviewRequest=true",
  },
  {
    title: "Navigate to social media tab",
    description: "navigate_social",
    action_url: "/social",
  },
];

const quickActionsHome = [
  {
    header: "Write an email",
    subheading: "about a particular topic",
    message: "Write an email about a particular topic",
  },
  {
    header: "Write a social media post",
    subheading: "with an attached photo",
    message: "Write a social media post with an attached photo",
  },
  {
    header: "Send a review request",
    subheading: "",
    message: "Send a review request",
  },
  {
    header: "Contact Us",
    subheading: "",
    message: "Contact Us",
  },
];

export function getQuickActions() {
  return quickActionsHome;
}

export function isPrimaryPage(path: string) {
  if (!path) {
    return false;
  }

  const primaryPages = [
    "/social",
    "/site",
    "/site/blogs",
    "/site/showcases",
    "/site/info",
    "/site/services",
    "/site/faqs",
    "/site/qrcode",
    "/site/seo",
    "/reviews",
    "/marketing",
    "/marketing/digital-media",
    "/marketing/physical-marketing",
    "/bookings",
    "/payments",
    "/payments/quotes",
    "/payments/payouts",
    "/settings",
  ];
  return primaryPages.includes(path);
}
