import React, { createContext, useContext, useState } from "react";

import CustomerSearch from "../CustomerSearch/CustomerSearch";
import LandingPageSearch from "../LandingPageSearch/LandingPageSearch";
import { AdminContext } from "../../../contexts/AdminDesktopNavigationContext/AdminDesktopNavigationContext";

export const TopLineAdminDesktopNavigationContext = createContext(
  {} as {
    showCustomerSearch: boolean;
    setShowCustomerSearch: (value: boolean) => void;
    showLandingPageSearch: boolean;
    setShowLandingPageSearch: (value: boolean) => void;
  }
);

const TopLineAdminDesktopNavigation = () => {
  const { isToplineProAdminUser } = useContext(AdminContext);

  const [showCustomerSearch, setShowCustomerSearch] = useState(false);
  const [showLandingPageSearch, setShowLandingPageSearch] = useState(false);

  const contextValue = {
    showCustomerSearch,
    setShowCustomerSearch,
    showLandingPageSearch,
    setShowLandingPageSearch,
  };

  return (
    <>
      {isToplineProAdminUser && (
        <>
          <TopLineAdminDesktopNavigationContext.Provider value={contextValue}>
            <CustomerSearch />
            <LandingPageSearch />
          </TopLineAdminDesktopNavigationContext.Provider>
        </>
      )}
    </>
  );
};

export default TopLineAdminDesktopNavigation;
