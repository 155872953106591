import { useEffect, useRef } from "react";

export default function BlueTooltip({
  isOpen,
  setOpen,
  header,
  subheader,
  arrowDirection,
  arrowPosition,
  relativePosition,
}: any) {
  const tooltipRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(e: any) {
      if (tooltipRef?.current && !tooltipRef?.current?.contains(e?.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div
        ref={tooltipRef}
        style={relativePosition}
        className="flex bg-blue-600 absolute rounded-md z-50 tooltip-container"
      >
        <div
          className="text-white py-5 px-4 relative"
          style={{ width: "316px" }}
        >
          <div className="text-white">
            <div className="flex justify-between">
              <div className="text-h3-bold mb-2.5">{header}</div>
            </div>
            <div className="text-body-normal">{subheader}</div>
          </div>

          <div
            style={arrowPosition}
            className={`absolute ${arrowDirection}`}
          ></div>
        </div>
      </div>
    </>
  );
}
