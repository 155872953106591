import Link from "next/link";
import React, { useContext, useEffect, useState } from "react";
import { MobileContext } from "../../../contexts/MobileContextProvider/MobileContextProvider";
import TopeLineProBlueIcon from "../../common/TopeLineProBlueIcon/TopeLineProBlueIcon";
import { CONSTANTS } from "../constants";
import { useRouter } from "next/router";
import { DesktopContext } from "../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { Tooltip } from "@material-tailwind/react";
import HighlightedTabWithTooltip from "../DesktopNavigationExpanded/HighlightedTabWithTooltip";

export default function MobileHeader() {
  const router = useRouter();
  const [showNavHeaderBar, setShowNavHeaderBar] = useState(false);
  const {
    selectedFrame,
    mobileMenuOpen,
    bottomLeftNavigation,
    isMobileNavHeaderOpen,
  } = useContext(MobileContext);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const {
    downloadAppOnIphoneWithNoHomeButton,
    showContactsTooltip,
    showReviewsTooltip,
  } = useContext(DesktopContext);

  const mobileHeaderSelectedNavbar = selectedFrame
    .concat(bottomLeftNavigation)
    .filter((item) => CONSTANTS.MOBILE_HEADER_NAV_OPTION.includes(item.name));

  const checkIfSelected = (item: string) => {
    return router.pathname.includes(item);
  };

  useEffect(() => {
    const validRoutesToShowNavHeaderBar = [
      "home",
      "bookings",
      "contacts",
      "settings",
      "marketing",
      "payments",
    ];
    const inValidRoutesToShowNavHeaderBar = [
      "marketing/createCampaign",
      "marketing/digital-media-create",
      "marketing/digital-media-video-create",
    ];
    const showNavHeaderBar = validRoutesToShowNavHeaderBar.some((route) => {
      return router.pathname.includes(route);
    });

    const anyInValidRouteToShowNavHeaderBar =
      inValidRoutesToShowNavHeaderBar.some((route) => {
        return router.pathname.includes(route);
      });
    if (anyInValidRouteToShowNavHeaderBar) {
      setShowNavHeaderBar(false);
    } else {
      setShowNavHeaderBar(showNavHeaderBar);
    }
  }, [router?.pathname]);

  const isHomeRoute = router.pathname.includes("/home");

  return (
    <>
      {showNavHeaderBar && isMobileNavHeaderOpen && (
        <div
          id="mobile-head-navbar"
          className={`lg:hidden sticky top-0 z-0 ${
            isScrolled || !isHomeRoute ? "shadow-md" : ""
          } ${
            downloadAppOnIphoneWithNoHomeButton
              ? "transform -translate-y-12"
              : ""
          }`}
        >
          <div
            className={`bg-white py-4 px-4 flex justify-between sm:px-6 lg:px-8 ${
              downloadAppOnIphoneWithNoHomeButton
                ? "h-24 w-screen items-end"
                : "items-center"
            }`}
          >
            <div className="flex-none">
              <Link href="/home">
                <a>
                  <TopeLineProBlueIcon />
                </a>
              </Link>
            </div>
            <div className=" shadow-3xl flex justify-between items-center rounded-md space-x-4">
              {mobileHeaderSelectedNavbar.map((item) => (
                <div key={item.name}>
                  {showContactsTooltip && item.name === "Contacts" ? (
                    <div className="block sm:hidden" style={{ zIndex: 9999 }}>
                      <Tooltip
                        content={
                          <div className="block sm:hidden">
                            <HighlightedTabWithTooltip />
                          </div>
                        }
                        placement="bottom"
                        open={showContactsTooltip || showReviewsTooltip}
                        className="bg-clear"
                        offset={18}
                        dismiss={{
                          enabled: true,
                          outsidePointerDown: true,
                        }}
                      >
                        <div className="rounded-full border-4 border-blue-300">
                          <div className="p-1.5 rounded-full border-2 border-blue-500">
                            <item.icon
                              className={`h-6 w-6`}
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <Link key={item.name} href={item?.href}>
                      <a
                        target={item?.target}
                        className={
                          "group  rounded-md flex items-center text-body-medium text-gray-500 hover:text-gray-900 cursor-pointer tap-highlight-transparent"
                        }
                      >
                        <div
                          id={`${item.name.toLowerCase()}`}
                          className={`flex flex-col justify-center items-center align-middle text-center ${
                            checkIfSelected(item?.href.toLocaleLowerCase()) &&
                            !mobileMenuOpen
                              ? CONSTANTS.MOBILE_SELECTED_NAV_ICON_COLOR
                              : CONSTANTS.MOBILE_UNSELECTED_NAV_ICON_COLOR
                          }`}
                        >
                          {checkIfSelected(item?.href.toLocaleLowerCase()) ? (
                            <item.selectedIcon
                              className={`h-10 w-10 text-gray-500`}
                              aria-hidden="true"
                            />
                          ) : (
                            <item.icon
                              className={`h-6 w-6`}
                              aria-hidden="true"
                            />
                          )}
                        </div>
                      </a>
                    </Link>
                  )}
                  {}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
