import React, { useContext, useEffect } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { DesktopContext } from "../../../contexts/DesktopContextProvider/DesktopContextProvider";
import useIsMobile from "../../../hooks/useIsMobile";
import { fixHeightOfBody } from "../UtilityModal/utils";

interface SlideInModalProps {
  open: boolean;
  onCloseFunction: () => void;
  children: any;
  childrenModals?: any;
  header: string;
  headerStyle?: string;
  leftIconStyle?: string;
  headerRightElement?: any;
  buttons?: any;
  showDesktopLeftArrow?: boolean;
}

export default function SlideInModal({
  open,
  onCloseFunction,
  children,
  childrenModals,
  header,
  headerStyle,
  leftIconStyle,
  headerRightElement = null,
  buttons,
  showDesktopLeftArrow = false,
}: SlideInModalProps): React.ReactElement {
  const { downloadAppOnIphoneWithNoHomeButton } = useContext(DesktopContext);
  const isMobile = useIsMobile();

  useEffect(() => {
    fixHeightOfBody(isMobile, open);
  }, [open]);

  function handleClose() {
    const targetElement = event?.target as Element;
    const toastClicked = targetElement?.closest(".Toastify");
    if (toastClicked) {
      return;
    }

    onCloseFunction();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="z-30 fixed inset-0 sm:overflow-hidden"
        onClose={handleClose}
      >
        <div className="fixed inset-0 sm:overflow-hidden">
          <Dialog.Overlay className="fixed inset-0" />

          <div className="pointer-events-none fixed sm:top-0 right-0 flex h-full sm:pb-0 sm:w-500">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen sm:w-full bg-white">
                <div
                  className={`flex flex-col h-full shadow-md ${
                    downloadAppOnIphoneWithNoHomeButton ? "pb-4" : ""
                  }`}
                >
                  <div className="flex w-full text-center sm:flex-row justify-between p-6 bg-white sm:bg-gray-50 align-middle items-top sm:text-left border-b sm:border-none">
                    <div className="flex w-full justify-between items-center">
                      <ChevronLeftIcon
                        id="close-contact-icon"
                        data-cy="close-contact-icon"
                        className={`${
                          leftIconStyle
                            ? leftIconStyle
                            : "w-7 h-7 text-gray-600 hover:text-gray-500 cursor-pointer sm:hidden"
                        }`}
                        onClick={handleClose}
                      />
                      <h3
                        className={`${
                          headerStyle
                            ? headerStyle
                            : "flex items-center text-gray-900 text-h3-medium"
                        }`}
                      >
                        {showDesktopLeftArrow && (
                          <ChevronLeftIcon
                            className="w-6 h-6 text-gray-600 hover:text-gray-500 cursor-pointer mr-2"
                            onClick={handleClose}
                          />
                        )}
                        {header}
                      </h3>

                      {headerRightElement ? (
                        headerRightElement
                      ) : (
                        <div className="sm:hidden w-7 h-7"></div>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col flex-grow h-full overflow-y-auto">
                    {children}
                  </div>

                  {buttons && <>{buttons}</>}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>

        <>{childrenModals}</>
      </Dialog>
    </Transition.Root>
  );
}
