// create a context for the AdminDesktopNavigationContext

import React, { createContext, useContext, useEffect, useState } from "react";
import { DesktopContext } from "../DesktopContextProvider/DesktopContextProvider";

export interface IAdminContext {
  isToplineProAdminUser: boolean;
  setIsToplineProAdminUser: (value: boolean) => void;
}

export const AdminContext = createContext({} as IAdminContext);

export const AdminContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { profileInfo } = useContext(DesktopContext);
  const [isToplineProAdminUser, setIsToplineProAdminUser] = useState(false);

  useEffect(() => {
    const adminSha = localStorage.getItem("adminSha");
    if (
      profileInfo?.user_type === "Topline Admin" ||
      (adminSha && adminSha === "ab462da2-8d2f-4ce8-8a7b-93d70f3d0882")
    ) {
      if (!adminSha) {
        localStorage.setItem(
          "adminSha",
          "ab462da2-8d2f-4ce8-8a7b-93d70f3d0882"
        );
      }
      setIsToplineProAdminUser(true);
    }
  }, [profileInfo?.user_type]);

  const contextValue = {
    isToplineProAdminUser,
    setIsToplineProAdminUser,
  };
  return (
    <AdminContext.Provider value={contextValue}>
      {children}
    </AdminContext.Provider>
  );
};
