import { ArrowRightIcon } from "@heroicons/react/solid";
import moment from "moment";

export default function ChatHistory({ selectConversation, message }: any) {
  return (
    <div onClick={() => selectConversation(message)} className="cursor-pointer">
      <div>{moment(message.created_at).format("MMM D, YYYY")}</div>
      <div className="flex items-center justify-between p-2 mt-1 mb-2 bg-gray-200 rounded">
        <div>{message.title}</div>
        <div>
          <ArrowRightIcon className="h-7 w-7 rounded-full p-1 text-gray-700" />
        </div>
      </div>
    </div>
  );
}
