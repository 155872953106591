import React, { ReactNode } from "react";
import { AppProvider } from "../../contexts/AppContextProvider/AppContextProvider";
import { DesktopContextProvider } from "../../contexts/DesktopContextProvider/DesktopContextProvider";
import { MobileContextProvider } from "../../contexts/MobileContextProvider/MobileContextProvider";
import { PaymentsContextProvider } from "../../components/primaryrender/payments/PaymentsContextProvider/PaymentsContextProvider";
import { AdminContextProvider } from "../../contexts/AdminDesktopNavigationContext/AdminDesktopNavigationContext";
import HeapAnalytics from "../../analytics/HeapAnalytics/HeapAnalytics";
import { SelectedSiteContextProvider } from "../../contexts/SelectedSiteContextProvider/SelectedSiteContextProvider";
import { AuthGuardInterceptor } from "../../components/AuthGuardInterceptor/AuthGuardInterceptor";

const ContextLayoutLayer: React.FC = ({ children }) => {
  return (
    <AppProvider>
      <AuthGuardInterceptor />
      <SelectedSiteContextProvider>
        <MobileContextProvider>
          <DesktopContextProvider>
            <AdminContextProvider>
              <HeapAnalytics />
              <PaymentsContextProvider>{children}</PaymentsContextProvider>
            </AdminContextProvider>
          </DesktopContextProvider>
        </MobileContextProvider>
      </SelectedSiteContextProvider>
      <AuthGuardInterceptor />
    </AppProvider>
  );
};

export const getLayout = (page: ReactNode): ReactNode => (
  <ContextLayoutLayer>{page}</ContextLayoutLayer>
);

export default ContextLayoutLayer;
