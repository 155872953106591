export default function SquarePlusIconSolid({ className = "" }: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3 0.5C1.61929 0.5 0.5 1.61929 0.5 3V4.875C0.5 6.25571 1.61929 7.375 3 7.375H4.875C6.25571 7.375 7.375 6.25571 7.375 4.875V3C7.375 1.61929 6.25571 0.5 4.875 0.5H3Z"
        fill="white"
      />
      <path
        d="M11.125 0.5C9.74429 0.5 8.625 1.61929 8.625 3V4.875C8.625 6.25571 9.74429 7.375 11.125 7.375H13C14.3807 7.375 15.5 6.25571 15.5 4.875V3C15.5 1.61929 14.3807 0.5 13 0.5H11.125Z"
        fill="white"
      />
      <path
        d="M3 8.625C1.61929 8.625 0.5 9.74429 0.5 11.125V13C0.5 14.3807 1.61929 15.5 3 15.5H4.875C6.25571 15.5 7.375 14.3807 7.375 13V11.125C7.375 9.74429 6.25571 8.625 4.875 8.625H3Z"
        fill="white"
      />
      <path
        d="M12.6875 9.25C12.6875 8.90482 12.4077 8.625 12.0625 8.625C11.7173 8.625 11.4375 8.90482 11.4375 9.25V11.4375H9.25C8.90482 11.4375 8.625 11.7173 8.625 12.0625C8.625 12.4077 8.90482 12.6875 9.25 12.6875H11.4375V14.875C11.4375 15.2202 11.7173 15.5 12.0625 15.5C12.4077 15.5 12.6875 15.2202 12.6875 14.875V12.6875H14.875C15.2202 12.6875 15.5 12.4077 15.5 12.0625C15.5 11.7173 15.2202 11.4375 14.875 11.4375H12.6875V9.25Z"
        fill="white"
      />
    </svg>
  );
}
