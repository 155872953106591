import { ArrowUpIcon } from "@heroicons/react/solid";

export default function QuickAction({ handleQuickAction, action }: any) {
  return (
    <div
      onClick={() => handleQuickAction(action)}
      className="flex items-center justify-between p-2 bg-gray-200 rounded cursor-pointer"
    >
      <div>
        <div className="text-gray-700 text-sm-normal">{action.header}</div>
        <div className="text-gray-500 text-xs-normal">{action.subheading}</div>
      </div>
      <div>
        <ArrowUpIcon className="h-7 w-7 hover:cursor-pointer rounded-full p-1 text-gray-700" />
      </div>
    </div>
  );
}
