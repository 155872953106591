import { SparklesIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import ChatLoader from "./ChatLoader";

export default function ChatMessage({ message, loading }) {
  const { basicInfo } = useContext(DesktopContext);
  const logo = basicInfo?.logo;

  const [copyText, setCopyText] = useState("Copy");

  function transformContent(content) {
    const regex = /```(.*?)```/gs;
    let lastIndex = 0;
    const transformedContent = [];
    let match;

    while ((match = regex.exec(content)) !== null) {
      if (match.index > lastIndex) {
        transformedContent.push(
          <div key={lastIndex}>{content.substring(lastIndex, match.index)}</div>
        );
      }

      const text = match[1];

      transformedContent.push(
        <div
          key={match.index}
          className="copyable border border-gray-300 rounded-lg overflow-hidden mt-2"
        >
          <div className="flex justify-end w-full bg-gray-200 text-blue-600 p-2">
            <div
              onClick={() => copyToClipboard(text.trim())}
              className="cursor-pointer"
            >
              {copyText}
            </div>
          </div>
          <div className="p-3">{text.trim()}</div>
        </div>
      );

      lastIndex = regex.lastIndex;
    }

    if (lastIndex < content?.length) {
      transformedContent.push(
        <div key={lastIndex}>{content.substring(lastIndex)}</div>
      );
    }

    return transformedContent;
  }

  function copyToClipboard(string) {
    const formattedString = string.replace(/<[^>]+>/g, "").replace(/```/g, "");
    navigator.clipboard.writeText(formattedString);

    setCopyText("Copied!");
    setTimeout(() => {
      setCopyText("Copy");
    }, 1000);
  }

  if (message?.role === "system") {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-start py-3">
        <div className="flex items-center">
          <div className="flex flex-shrink-0 w-5 h-5 mr-3">
            {message?.role === "assistant" ? (
              <SparklesIcon className="w-5 h-5 text-purple-400" />
            ) : (
              <img
                className="w-5 h-5 rounded-full object-cover"
                src={logo}
                alt="logo"
              />
            )}
          </div>
          <div className="text-gray-900 text-body-bold">
            {message?.role === "assistant" ? "Topline Pro Assistant" : "You"}
          </div>
        </div>

        <div className="flex flex-col pl-8 items-start text-gray-900 w-full whitespace-pre-wrap sm:text-sm-normal">
          {message?.image && (
            <div className="pb-2">
              <img className="w-full h-40 object-cover" src={message?.image} />
            </div>
          )}
          {loading ? <ChatLoader /> : transformContent(message.content)}
        </div>
      </div>
    </div>
  );
}
