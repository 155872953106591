import { useRouter } from "next/router";
import { Float } from "@headlessui-float/react";
import { Menu } from "@headlessui/react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import { DesktopContext } from "../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { useContext, useMemo } from "react";
import { SelectedSiteContext } from "../../../contexts/SelectedSiteContextProvider/SelectedSiteContextProvider";

export default function SiteSelector() {
  const router = useRouter();
  const { profileInfo } = useContext(DesktopContext);
  const { selectedSiteId, setSelectedSiteId } = useContext(SelectedSiteContext);

  const selectedSite = useMemo(() => {
    if (!profileInfo) return;
    return profileInfo.landing_pages.find(
      ({ landing_page_pk }) => landing_page_pk == selectedSiteId
    );
  }, [profileInfo, selectedSiteId]);

  const sites = useMemo(() => {
    if (!profileInfo) return [];
    return profileInfo.landing_pages;
  }, [profileInfo]);

  const handleSiteSelection = (page_key: number) => {
    setSelectedSiteId(page_key);
    router.reload();
  };

  if (sites.length <= 1) return <></>;

  return (
    <nav className="mx-2">
      <Menu>
        {({ open }) => (
          <Float
            placement="bottom-start"
            strategy="fixed"
            offset={6}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Button
              className={`bg-transparent ${
                open
                  ? "bg-gray-500 text-white"
                  : "text-gray-200 hover:bg-gray-600"
              } rounded-md ring-2 ring-gray-500 flex flex-row items-center justify-between p-2 w-full`}
            >
              {selectedSite?.company_name}
              {open ? (
                <ChevronUpIcon className="w-4 h-4"></ChevronUpIcon>
              ) : (
                <ChevronDownIcon className="w-4 h-4"></ChevronDownIcon>
              )}
            </Menu.Button>
            <Menu.Items
              className="rounded-md bg-white shadow-lg z-50 overflow-hidden"
              style={{ width: "342px" }}
            >
              <div className="font-medium text-gray-900 py-3 px-6 text-sm">
                My Businesses
              </div>
              {sites.map((item) => (
                <Menu.Item key={item.landing_page_pk}>
                  <div
                    className={`flex flex-row items-center justify-between py-4 px-6 ${
                      item.landing_page_pk === selectedSiteId
                        ? "bg-blue-50"
                        : "hover:bg-gray-100 cursor-pointer"
                    } `}
                    onClick={() => handleSiteSelection(item.landing_page_pk)}
                  >
                    <div>
                      <p className="font-semibold text-gray-900 text-sm">
                        {item.company_name}
                      </p>
                      <p className="font-normal text-gray-500 text-xs">
                        {item.domain}
                      </p>
                    </div>
                    {item.landing_page_pk === selectedSiteId ? (
                      <CheckIcon className="w-5 h-5 text-blue-500" />
                    ) : (
                      <ChevronRightIcon className="w-5 h-5 text-gray-500" />
                    )}
                  </div>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Float>
        )}
      </Menu>
    </nav>
  );
}
