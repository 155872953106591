import { ArrowDownIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { AdminContext } from "../../../contexts/AdminDesktopNavigationContext/AdminDesktopNavigationContext";
import { TopLineCustomerType } from "./types";

const ToplineProAdminToggle = () => {
  const { isToplineProAdminUser } = useContext(AdminContext);
  const [showToplineProAdminToggle, setShowToplineProAdminToggle] =
    useState(false);
  const { setIsToplineProAdminUser } = useContext(AdminContext);
  const [isActuallyToplineProAdminUser, setIsActuallyToplineProAdminUser] =
    useState(false);

  useEffect(() => {
    setIsActuallyToplineProAdminUser(isToplineProAdminUser);
  }, []);

  const [selectedCustomer, setSelectedCustomer] = useState(
    isToplineProAdminUser
      ? TopLineCustomerType.TopLineProAdmin
      : TopLineCustomerType.PrimaryOwner
  );

  const handleToplineProAdminToggle = () => {
    setShowToplineProAdminToggle(!showToplineProAdminToggle);
  };

  const handleSelectCustomer = (e: any) => {
    const selectedCustomerType = e.target.value;
    setSelectedCustomer(selectedCustomerType);
    if (selectedCustomerType == TopLineCustomerType.PrimaryOwner) {
      setIsToplineProAdminUser(false);
    }
    if (selectedCustomerType == TopLineCustomerType.TopLineProAdmin) {
      setIsToplineProAdminUser(true);
    }
    setShowToplineProAdminToggle(false);
  };
  return (
    <>
      {isActuallyToplineProAdminUser && (
        <>
          <div data-testid="topline-pro-admin-toggle">
            <div className="relative">
              <div
                onClick={handleToplineProAdminToggle}
                className=" border p-3 rounded text-white shadow-inner w-full flex cursor-pointer"
              >
                <span className="float-left break-all text-gray-300">
                  {selectedCustomer}
                </span>
                <ArrowDownIcon className="w-4 h-6" />
              </div>
              {showToplineProAdminToggle && (
                <div className="rounded shadow-md my-2 absolute pin-t pin-l bg-white">
                  <ul className="list-reset">
                    <li className="p-2">
                      <h2>Search LandingPage</h2>
                      {/* select option 1 primary owner 2 toplinepro admin */}
                      <select
                        className="w-full border p-2 rounded"
                        onChange={handleSelectCustomer}
                        value={selectedCustomer}
                      >
                        <option value={TopLineCustomerType.TopLineProAdmin}>
                          {TopLineCustomerType.TopLineProAdmin}
                        </option>
                        <option value={TopLineCustomerType.PrimaryOwner}>
                          {TopLineCustomerType.PrimaryOwner}
                        </option>
                      </select>
                      <br />
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ToplineProAdminToggle;
