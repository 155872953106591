import React from "react";
import {
  CheckCircleIcon,
  LocationMarkerIcon,
  XIcon,
  XCircleIcon,
  InformationCircleIcon,
  ExclamationIcon,
  LockClosedIcon,
  ArrowSmRightIcon,
  ClockIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/solid";
import { PaperAirplaneIcon } from "@heroicons/react/outline";
import PrimaryLink from "../../uiwrappers/PrimaryLink/PrimaryLink";
import MegaphoneIcon from "../Icons/MegaphoneIcon/MegaphoneIcon";

interface BannerProps {
  bannerText: any;
  type: string;
  icon: string;
  showBannerCta: boolean;
  bannerCtaText: string;
  bannerCtaFunction: any;
  closeBanner: any;
  customWidth: string;
  exitIcon: boolean;
  rounded: boolean;
  secondLine?: any;
  secondLineActionText?: any;
  secondLineActionFunction?: () => void;
  trailingArrow?: boolean;
  underlineCtaText?: boolean;
  bannerCtaTextStyle?: string;
  subBannerText?: string;
  trailingText?: string;
  customStyle?: string;
  linkText?: string;
  linkUrl?: string;
}

const Banner = ({
  bannerText,
  type,
  icon,
  showBannerCta,
  bannerCtaText,
  bannerCtaFunction,
  closeBanner,
  customWidth,
  exitIcon,
  rounded,
  secondLine,
  secondLineActionText,
  secondLineActionFunction,
  trailingArrow = false,
  underlineCtaText = true,
  bannerCtaTextStyle,
  subBannerText,
  trailingText,
  customStyle,
  linkText,
  linkUrl,
}: BannerProps) => {
  function renderIconStyle() {
    switch (type) {
      case "alert":
        return "text-red-800";
      case "yellow":
        return "text-amber-800";
      case "custom-blue":
        return "text-blue-800";
      case "mid-blue":
        return "text-blue-600";
      default:
        return "text-blue-400";
    }
  }

  function renderBannerStyle() {
    switch (type) {
      case "alert":
        return "bg-red-50 text-red-800";
      case "yellow":
        return "bg-amber-50 text-amber-800";
      case "custom-blue":
        return "bg-blue-50 text-blue-800";
      default:
        return "bg-blue-50 text-blue-800";
    }
  }

  function renderIcon() {
    switch (icon) {
      case "lock":
        return (
          <LockClosedIcon
            className="h-4 w-5 sm:h-5 sm:w-5 text-amber-400 flex-shrink-0"
            aria-hidden="true"
          />
        );
      case "location":
        return (
          <LocationMarkerIcon
            className="h-4 w-5 sm:h-5 sm:w-5 text-blue-400 flex-shrink-0"
            aria-hidden="true"
          />
        );
      case "warning":
        return (
          <ExclamationIcon
            className={`h-4 w-5 sm:h-5 sm:w-5 ${renderIconStyle()} flex-shrink-0`}
            aria-hidden="true"
          />
        );
      case "success":
        return (
          <CheckCircleIcon
            className="h-4 w-5 sm:h-5 sm:w-5 text-green-400 flex-shrink-0"
            aria-hidden="true"
          />
        );

      case "alert":
        return (
          <XCircleIcon
            className="h-4 w-5 sm:h-5 sm:w-5 text-red-400 flex-shrink-0"
            aria-hidden="true"
          />
        );

      case "information":
        return (
          <InformationCircleIcon
            className={`h-4 w-5 sm:h-5 sm:w-5 ${renderIconStyle()} flex-shrink-0`}
            aria-hidden="true"
          />
        );
      case "paper-plane":
        return (
          <PaperAirplaneIcon
            className={`h-4 w-5 sm:h-5 sm:w-5 ${renderIconStyle()} flex-shrink-0 transform rotate-45`}
            aria-hidden="true"
          />
        );
      case "clock":
        return (
          <ClockIcon
            className={`h-4 w-5 sm:h-5 sm:w-5 ${renderIconStyle()} flex-shrink-0`}
            aria-hidden="true"
          />
        );
      case "circle-exclaimation":
        return (
          <ExclamationCircleIcon
            className={`h-4 w-5 sm:h-5 sm:w-5 ${renderIconStyle()} flex-shrink-0`}
            aria-hidden="true"
          />
        );
      case "megaphone":
        return (
          <MegaphoneIcon
            className={`h-4 w-5 sm:h-5 sm:w-5 ${renderIconStyle()} flex-shrink-0`}
            aria-hidden="true"
          />
        );
      default:
        return (
          <InformationCircleIcon
            className={`h-4 w-5 sm:h-5 sm:w-5 ${renderIconStyle()} flex-shrink-0`}
            aria-hidden="true"
          />
        );
    }
  }

  return (
    <>
      <div
        className={`${
          customWidth || "w-full"
        } flex sm:flex-row sm:items-center px-3 py-4 justify-between ease-out ${renderBannerStyle()} ${
          rounded && "rounded-md"
        } ${customStyle}`}
      >
        <div
          className={`flex w-full ${
            secondLine ? "items-start" : "items-center"
          }`}
        >
          {icon && !trailingText && (
            <div className="self-start">{renderIcon()}</div>
          )}
          <div className="ml-2 sm:ml-4 flex flex-wrap gap-y-2  justify-between w-full">
            <h1 className={`text-sm-semibold ${trailingText && "flex gap-1"}`}>
              {bannerText != "ProPhone is now Topline Pro" && trailingText ? (
                <>
                  <PrimaryLink
                    size="md"
                    text={bannerText}
                    icon="send-paper-plane"
                    iconPlacement="leading"
                    customStyle="font-normal"
                  />
                  <span className="hidden sm:inline text-gray-700 font-normal">
                    {trailingText}
                  </span>
                </>
              ) : (
                <>
                  {bannerText}{" "}
                  <span className="hidden sm:inline text-gray-700 font-normal">
                    {trailingText}
                  </span>
                </>
              )}
              {bannerText == "ProPhone is now Topline Pro" ? (
                <span>
                  <b>ProPhone</b> is now <b>Topline Pro</b>
                </span>
              ) : (
                <></>
              )}
            </h1>
            {subBannerText && (
              <p className="text-sm-normal mt-2">{subBannerText}</p>
            )}

            {secondLine && (
              <div className="text-sm-normal w-full">
                {secondLine}{" "}
                {secondLineActionText && (
                  <span
                    className={`text-body-bold text-sm cursor-pointer ${
                      underlineCtaText ? "underline" : ""
                    }`}
                    onClick={secondLineActionFunction}
                  >
                    {secondLineActionText}
                  </span>
                )}
              </div>
            )}
            <div className="flex flex-row sm:flex-row whitespace-nowrap items-center gap-x-2">
              {linkText && linkUrl && (
                <span
                  onClick={() => window.open(linkUrl, "_blank")}
                  className={`cursor-pointer ${
                    underlineCtaText && "underline"
                  } ${
                    bannerCtaTextStyle ? bannerCtaTextStyle : "text-sm-semibold"
                  }`}
                >
                  {linkText}
                </span>
              )}
              {showBannerCta && (
                <div
                  onClick={bannerCtaFunction}
                  className="flex items-center cursor-pointer space-x-1"
                >
                  <h1
                    className={` ${
                      bannerCtaTextStyle
                        ? bannerCtaTextStyle
                        : "text-sm-semibold"
                    } ${underlineCtaText && "underline"}`}
                  >
                    {bannerCtaText}
                  </h1>
                  {trailingArrow && (
                    <div className="">
                      <ArrowSmRightIcon
                        className="h-4 w-4 sm:h-5 sm:w-5 flex-shrink-0"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <span className="block sm:hidden text-gray-700 font-semibold">
          {trailingText}
        </span>
        {exitIcon && (
          <XIcon
            className="h-5 w-5 flex-shrink-0 ml-2 cursor-pointer"
            aria-hidden="true"
            onClick={closeBanner}
          />
        )}
      </div>
    </>
  );
};

Banner.defaultProps = {
  bannerText: "",
  type: "",
  icon: "",
  showBannerCta: false,
  bannerCtaText: "",
  bannerCtaFunction: () => {},
  closeBanner: () => {},
  customWidth: "",
  exitIcon: false,
  rounded: false,
  multipleBanners: false,
  linkText: "",
  linkUrl: "",
};

export default Banner;
