import React, { ReactNode, useContext, useEffect, useState } from "react";
import DesktopNavigationExpanded from "../../components/navbar/DesktopNavigationExpanded/DesktopNavigationExpanded";
import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";
import ContextLayoutLayer from "../ContextLayoutLayer/ContextLayoutLayer";
import { useRouter } from "next/router";
import MobileBottomNavBar from "../../components/navbar/MobileBottomNavBar/MobileBottomNavBar";
import { MobileContext } from "../../contexts/MobileContextProvider/MobileContextProvider";
import { useShowBanner } from "../../hooks/useShowBanner";
import MobileHeader from "../../components/navbar/MobileHeader/MobileHeader";
import { Tooltip } from "react-tooltip";

import useIsMobile from "../../hooks/useIsMobile";
import { DESKTOP_NAVIGATION_EXPANDED_WIDTH } from "../../components/navbar/DesktopNavigationExpanded/constant";
import Chatbot from "../../components/primaryrender/dashboard/Chatbot/Chatbot";
import FullPageLoading from "../../components/FullPageLoading/FullPageLoading";

const SiteLayout: React.FC = ({ children }) => {
  const isMobile = useIsMobile();
  const {
    loading,
    paymentFailureError,
    isLoginVerified,
    showContactsTooltip,
    showReviewsTooltip,
    setShowContactsTooltip,
    setShowReviewsTooltip,
  } = useContext(DesktopContext);
  const { mobileMenuOpen, handleMobileMenuOpen } = useContext(MobileContext);
  const [, setIsLoading] = useState(loading);
  const [showNavHeaderBar, setShowNavHeaderBar] = useState(false);

  const router = useRouter();

  useEffect(() => {
    const validRoutesToShowNavHeaderBar = [
      "home",
      "bookings",
      "contacts",
      "settings",
      "marketing",
      "payments",
    ];
    const inValidRoutesToShowNavHeaderBar = ["marketing/createCampaign"];
    const showNavHeaderBar = validRoutesToShowNavHeaderBar.some((route) => {
      return router.pathname.includes(route);
    });

    const anyInValidRouteToShowNavHeaderBar =
      inValidRoutesToShowNavHeaderBar.some((route) => {
        return router.pathname.includes(route);
      });
    if (anyInValidRouteToShowNavHeaderBar) {
      setShowNavHeaderBar(false);
    } else {
      setShowNavHeaderBar(showNavHeaderBar);
    }

    if (router?.pathname === "/marketing/digital-media") {
      setShowNavHeaderBar(false);
    }
  }, [router?.pathname]);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsLoading(true);
      !mobileMenuOpen && handleMobileMenuOpen(true);
    };
    const handleRouteChangeComplete = () => {
      setIsLoading(false);
    };
    router.events.on("routeChangeStart", handleRouteChange);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    router.events.on("routeChangeError", handleRouteChangeComplete);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
      router.events.off("routeChangeError", handleRouteChangeComplete);
    };
  }, [router]);
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const overflowCss = router.pathname.includes("/generate-blog")
    ? "overflow-visible"
    : "";

  const {
    renderGmbUnverifiedBanner,
    renderGmbPendingBanner,
    renderGmbSuspendedBanner,
    renderFbExpiredBanner,
    renderDelinquentBanner,
    renderLegacyPricingBanner,
    renderChurnBanner,
    renderAdsPaymentFailureBanner,
    renderFbWaitlistBanner,
  } = useShowBanner();

  const isHomeRoute = router?.pathname === "/home";
  const hideBannerOnHomeMobile = isMobile && isHomeRoute;
  const hideNavbarUrls = [
    "/marketing/digital-media-create",
    "/marketing/digital-media-video-create",
    "/marketing/physical-marketing-create",
    "/marketing/physical-marketing-create-order",
    "/contacts",
  ];
  const showNavbarElement = !hideNavbarUrls.includes(router?.pathname);
  function handleClose() {
    if (showContactsTooltip) {
      setShowContactsTooltip(false);
      setShowReviewsTooltip(true);
    } else if (showReviewsTooltip) {
      setShowReviewsTooltip(false);
    }
  }

  if (!isLoginVerified) return <FullPageLoading />;
  return (
    <div className="h-full min-h-screen bg-gray-50">
      {isMobile && showNavbarElement && (
        <div
          id="mobile-navbar-parent"
          className={`bg-white sm:bg-gray-300 sm:h-0 sticky top-0 z-30 ${
            showNavHeaderBar ? "h-16" : ""
          }`}
        >
          <MobileHeader />
        </div>
      )}
      <div className={`sm:min-h-screen flex h-full ${overflowCss}`}>
        <DesktopNavigationExpanded />
        <div
          className={`bg-gray-50 h-full w-full flex-1 min-w-0 flex flex-col ${overflowCss}`}
        >
          {!loading && isLoginVerified && !hideBannerOnHomeMobile && (
            <div className="sticky top-0 z-10">
              {renderChurnBanner()}
              {renderGmbUnverifiedBanner()}
              {renderGmbPendingBanner()}
              {renderGmbSuspendedBanner()}
              {renderFbExpiredBanner()}
              {renderLegacyPricingBanner()}
              {paymentFailureError && renderDelinquentBanner()}
              {renderAdsPaymentFailureBanner()}
              {renderFbWaitlistBanner()}
            </div>
          )}

          {isLoginVerified && (
            <div>
              {(showContactsTooltip || showReviewsTooltip) && (
                <div
                  className="sm:absolute fixed top-0 h-full w-auto opacity-75 bg-gray-500 z-20 overflow-hidden"
                  style={{
                    height: "100vh",
                    width: isMobile
                      ? "100vw"
                      : `calc(100% - ${DESKTOP_NAVIGATION_EXPANDED_WIDTH})`,
                  }}
                  onClick={handleClose}
                ></div>
              )}
              <div
                className={`${
                  (showContactsTooltip || showReviewsTooltip) &&
                  "overflow-y-hidden"
                }`}
                style={{
                  height:
                    showContactsTooltip || showReviewsTooltip
                      ? "90vh"
                      : "100vh",
                }}
              >
                {children}
              </div>
            </div>
          )}
        </div>
      </div>
      <MobileBottomNavBar />
      {/* Tool tip is here as we now can put any tip anywhere in the project with TooltipForMetricsTile  */}
      <Tooltip id="my-tooltip" place="top" className="tooltip z-50" />

      <div
        style={{
          right: isMobile ? "1rem" : "2rem",
          bottom: isMobile ? "6rem" : "2rem",
        }}
        className="z-10 fixed"
      >
        <Chatbot />
      </div>
    </div>
  );
};

export const getLayout = (page: ReactNode): ReactNode => (
  <ContextLayoutLayer>
    <SiteLayout>{page}</SiteLayout>
  </ContextLayoutLayer>
);

export default SiteLayout;
