import {
  ExternalLinkIcon,
  UploadIcon,
  DownloadIcon,
  StarIcon,
  SparklesIcon,
  PhotographIcon,
  PencilAltIcon,
  UserCircleIcon,
  PaperAirplaneIcon,
  ChatAlt2Icon,
} from "@heroicons/react/outline";
import {
  ArrowRightIcon,
  ChatIcon,
  MailIcon,
  TrashIcon,
  PlusIcon,
  PencilIcon,
  ChevronDownIcon,
  ShareIcon,
} from "@heroicons/react/solid";
import React from "react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import BeforeAfterSvg from "../../primaryrender/marketing/DigitalMedia/BeforeAfterSvg";
import Image from "next/image";
import GenerateMediaIcon from "../../primaryrender/editor/NewPost/GenerateMediaIcon";
import SquarePlusIconSolid from "../../ProjectShowcases/SquarePlusIconSolid";
import QueueIcon from "../../common/Icons/QueueIcon/QueueIcon";

export interface PrimaryButtonProps {
  /**
   * most helpful are self alignment utility classes - self-end, self-start etc
   */
  text: string;
  size: string;
  onClickFunc?: any;
  width?: string;
  height?: string;
  spacing?: string;
  type?: "button" | "reset" | "submit";
  href?: string;
  disabled?: boolean;
  loading?: boolean;
  warning?: boolean;
  cancel?: boolean;
  icon?: any;
  iconPlacement?: string;
  id?: string;
  customStyle?: string;
  children?: any;
  loadingText?: string;
  orange?: boolean;
}

export default function PrimaryButton({
  text,
  size,
  onClickFunc,
  disabled,
  loading,
  warning,
  cancel,
  icon,
  iconPlacement,
  id,
  customStyle,
  children,
  loadingText,
  orange,
}: PrimaryButtonProps) {
  const spinnerSize = size;

  let style;
  let cssStyle;
  let textSize;

  if (loading) {
    style = "bg-blue-800 border border-blue-900";
  } else if (disabled) {
    style =
      "bg-gray-200 text-gray-400 border border-transparent cursor-not-allowed";
  } else if (warning) {
    style = "bg-red-100 text-red-600 border border-red-600";
  } else if (cancel) {
    style = "bg-white text-gray-700 border border-gray-300 hover:bg-gray-50";
  } else if (orange) {
    style =
      "border border-caro-orange bg-caro-orange text-white hover:bg-caro-orange-hover active:ring-2 active:ring-offset-2 active:ring-caro-orange focus:bg-caro-orange transition-colors duration-150 ease-in-out";
  } else {
    style =
      "border border-blue-900 bg-blue-900 text-white hover:bg-blue-800 active:ring-2 active:ring-offset-2 active:ring-blue-900 focus:bg-blue-900 transition-colors duration-150 ease-in-out";
  }

  let iconStyle = `flex flex-shrink-0 text-white`;
  function getIconStyle(size: string) {
    if (size === "xs" || size === "sm")
      return `h-4 w-4 ${iconStyle} ${
        iconPlacement === "leading" ? "mr-2" : "ml-2"
      }`;

    if (size === "md")
      return `h-5 w-5 ${iconStyle} ${
        iconPlacement === "leading" ? "mr-2" : "ml-2"
      }`;
    else
      return `h-5 w-5 ${iconStyle} ${
        iconPlacement === "leading" ? "mr-3" : "ml-3"
      }`;
  }

  switch (size) {
    case "xs":
      textSize = "text-xs";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "9px" : "11px",
        paddingRight: icon && iconPlacement === "lagging" ? "9px" : "11px",
        paddingTop: "5px",
        paddingBottom: "5px",
      };
      iconStyle = getIconStyle("xs");
      break;
    case "sm":
      textSize = "text-xs";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "11px" : "13px",
        paddingRight: icon && iconPlacement === "lagging" ? "11px" : "13px",
        paddingTop: "7px",
        paddingBottom: "7px",
      };
      iconStyle = getIconStyle("sm");
      break;
    case "md":
      textSize = "text-sm";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "15px" : "17px",
        paddingRight: icon && iconPlacement === "lagging" ? "15px" : "17px",
        paddingTop: "9px",
        paddingBottom: "9px",
      };
      iconStyle = getIconStyle("md");
      break;
    case "lg":
      textSize = "text-base";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "15px" : "17px",
        paddingRight: icon && iconPlacement === "lagging" ? "15px" : "17px",
        paddingTop: "9px",
        paddingBottom: "9px",
      };
      iconStyle = getIconStyle("lg");
      break;
    case "lg text only extra padding":
      textSize = "text-base";
      cssStyle = {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "9px",
        paddingBottom: "9px",
      };
      break;
    case "xl":
      textSize = "text-base";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "23px" : "25px",
        paddingRight: icon && iconPlacement === "lagging" ? "23px" : "25px",
        paddingTop: "13px",
        paddingBottom: "13px",
      };
      iconStyle = getIconStyle("xl");
      break;
    case "icon only lg":
      textSize = "text-base";
      cssStyle = {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        paddingLeft: "9px",
        paddingRight: "9px",
        paddingTop: "9px",
        paddingBottom: "9px",
      };
      iconStyle = "h-5 w-5 " + iconStyle;
      break;
    case "icon only xl":
      textSize = "text-base";
      cssStyle = {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        paddingLeft: "13px",
        paddingRight: "13px",
        paddingTop: "13px",
        paddingBottom: "13px",
      };
      iconStyle = "h-5 w-5 " + iconStyle;
      break;
  }

  if (icon) {
    switch (icon) {
      case "chat":
        icon = <ChatIcon className={iconStyle} />;
        break;
      case "chat-alt-2":
        icon = <ChatAlt2Icon className={iconStyle} />;
        break;
      case "mail":
        icon = <MailIcon className={iconStyle} />;
        break;
      case "external-link":
        icon = <ExternalLinkIcon className={iconStyle} />;
        break;
      case "plus":
        icon = <PlusIcon className={iconStyle} />;
        break;
      case "delete":
        icon = <TrashIcon className={iconStyle} />;
        break;
      case "upload":
        icon = <UploadIcon className={iconStyle} />;
        break;
      case "export":
        icon = <DownloadIcon className={iconStyle} />;
        break;
      case "right-arrow":
        icon = <ArrowRightIcon className={iconStyle} />;
        break;
      case "star":
        icon = <StarIcon className={iconStyle} />;
        break;
      case "pencil":
        icon = <PencilIcon className={iconStyle} />;
        break;
      case "pencil alt":
        icon = <PencilAltIcon className={iconStyle} />;
        break;
      case "down arrow":
        icon = <ChevronDownIcon className={iconStyle} />;
        break;
      case "sparkles":
        icon = <SparklesIcon className={iconStyle} />;
        break;
      case "photo":
        icon = <PhotographIcon className={iconStyle} />;
        break;
      case "user":
        icon = <UserCircleIcon className={iconStyle} />;
        break;
      case "before_after":
        icon = <BeforeAfterSvg className={iconStyle} />;
        break;
      case "generateMedia":
        icon = <GenerateMediaIcon className={iconStyle} />;
        break;
      case "share":
        icon = <ShareIcon className={iconStyle} />;
        break;
      case "square-plus":
        icon = <SquarePlusIconSolid className={iconStyle} />;
        break;
      case "paper-plane":
        icon = (
          <PaperAirplaneIcon
            className={`relative -top-0.5 transform rotate-45 ${iconStyle}`}
          />
        );
        break;
      case "queue":
        icon = (
          <div className={iconStyle}>
            <QueueIcon color="#FFFFFF" />
          </div>
        );
        break;
      case "facebook":
        icon = (
          <svg
            data-cy="facebook-post-icon"
            className={iconStyle}
            fill="currentColor"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        );
        break;
      case "instagram":
        icon = (
          <div className={iconStyle}>
            <Image
              src="/instagram-white.svg"
              width={20}
              height={20}
              alt="Instagram white"
            />
          </div>
        );
        break;
    }
  }

  if (customStyle) {
    style = style + " " + customStyle;
  }

  return (
    <>
      <button
        id={id || ""}
        type="button"
        data-cy={id}
        disabled={disabled}
        className={`${textSize} ${style} flex justify-center items-center rounded-full font-medium shadow focus:outline-none`}
        style={cssStyle}
        onClick={onClickFunc}
        data-testid="primary-button"
      >
        {iconPlacement === "leading" && icon}
        {loading ? (
          <div className="flex">
            {loadingText && (
              <div className="mr-1.5 text-white">{loadingText}</div>
            )}
            <LoadingSpinner
              size={
                spinnerSize === "lg" || spinnerSize === "xl"
                  ? "big-spinner"
                  : ""
              }
            />
          </div>
        ) : (
          text
        )}
        {iconPlacement === "lagging" && icon}
        {children}
      </button>
    </>
  );
}
